var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex-grow-1 flex-shrink-1",
      staticStyle: { "min-width": "0" }
    },
    [
      _vm.$route.name !== "app-open" && _vm.$route.params.oid
        ? _c(
            "div",
            { staticClass: "d-flex justify-start align-center" },
            [
              _c(
                "v-btn",
                {
                  class: _vm.$route.params.sid ? "ml-3" : "",
                  attrs: { text: "" },
                  on: { click: _vm.goToDashboard }
                },
                [
                  _c("v-icon", { attrs: { small: "", color: "primary" } }, [
                    _vm._v("home")
                  ])
                ],
                1
              ),
              _c("v-icon", [_vm._v("keyboard_arrow_right")]),
              _vm.showOrgMenu === true
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "close-on-content-click": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-shrink-1 full-width-button",
                                      attrs: {
                                        disabled: _vm.fetchingSpaces,
                                        text: "",
                                        color: "primary"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                          },
                                          [_vm._v(_vm._s(_vm.organizationName))]
                                        ),
                                        _c("v-icon", [
                                          _vm._v("arrow_drop_down")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2327450592
                      ),
                      model: {
                        value: _vm.organizationMenu,
                        callback: function($$v) {
                          _vm.organizationMenu = $$v
                        },
                        expression: "organizationMenu"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "overflow-y-auto",
                          staticStyle: { "max-height": "500px" },
                          attrs: { nav: "", dense: "" }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-text-field", {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  autofocus: "",
                                  "append-icon": "search",
                                  label: "Filter",
                                  dense: "",
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.organizationSearch,
                                  callback: function($$v) {
                                    _vm.organizationSearch = $$v
                                  },
                                  expression: "organizationSearch"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { attrs: { disabled: "" } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "primary--text caption text-uppercase d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flex-column",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("apartment")]
                                              ),
                                              _vm._v("organizations")
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mt-1",
                                            attrs: { color: "grey" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.sortedOrgNames, function(item) {
                            return _c(
                              "v-list-item",
                              {
                                key: item.long_id,
                                on: {
                                  click: function($event) {
                                    return _vm.fetchOrgSpaces(item.long_id)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between align-center"
                                    },
                                    [
                                      _vm._v(" " + _vm._s(item.long_id) + " "),
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "ml-3",
                                          attrs: {
                                            outlined: "",
                                            color: "grey lighten-1",
                                            "x-small": ""
                                          }
                                        },
                                        [_vm._v(_vm._s(item.role))]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-icon", [_vm._v("keyboard_arrow_right")]),
              _vm.showSpaceMenu === true
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "close-on-content-click": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-shrink-1 full-width-button",
                                      attrs: { text: "", color: "primary" }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                          },
                                          [_vm._v(_vm._s(_vm.spaceName))]
                                        ),
                                        _c("v-icon", [
                                          _vm._v("arrow_drop_down")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2611748882
                      ),
                      model: {
                        value: _vm.spaceMenu,
                        callback: function($$v) {
                          _vm.spaceMenu = $$v
                        },
                        expression: "spaceMenu"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "overflow-y-auto",
                          staticStyle: { "max-height": "500px" },
                          attrs: { nav: "", dense: "" }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-text-field", {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  autofocus: "",
                                  "append-icon": "search",
                                  label: "Filter",
                                  dense: "",
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.spaceSearch,
                                  callback: function($$v) {
                                    _vm.spaceSearch = $$v
                                  },
                                  expression: "spaceSearch"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.sortedEducationSpaces.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { disabled: "" } },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "primary--text caption text-uppercase d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("school")]
                                                      ),
                                                      _vm._v("courses")
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "mt-1",
                                                    attrs: { color: "grey" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.sortedEducationSpaces, function(
                                    item
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: item.sid,
                                        on: {
                                          click: function($event) {
                                            return _vm.openSpace(
                                              item.space_long_id,
                                              item.sid,
                                              item.iid,
                                              item.snid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between align-center"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.space_long_id) +
                                                  " "
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    outlined: "",
                                                    color: "grey lighten-1",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.spaceRole(
                                                        item.space_role
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.sortedResearchSpaces.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      class: _vm.sortedEducationSpaces.length
                                        ? "mt-5"
                                        : "",
                                      attrs: { disabled: "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "primary--text caption text-uppercase d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("mdi-beaker")]
                                                      ),
                                                      _vm._v(
                                                        "research projects"
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "mt-1",
                                                    attrs: { color: "grey" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.sortedResearchSpaces, function(
                                    item
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: item.sid,
                                        on: {
                                          click: function($event) {
                                            return _vm.openSpace(
                                              item.space_long_id,
                                              item.sid,
                                              item.iid,
                                              item.snid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between align-center"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.space_long_id) +
                                                  " "
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    outlined: "",
                                                    color: "grey lighten-1",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.spaceRole(
                                                        item.space_role
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.sortedDatasetSpaces.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      class:
                                        _vm.sortedResearchSpaces.length ||
                                        _vm.sortedEducationSpaces.length
                                          ? "mt-5"
                                          : "",
                                      attrs: { disabled: "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "primary--text caption text-uppercase d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("mdi-database")]
                                                      ),
                                                      _vm._v("datasets")
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "mt-1",
                                                    attrs: { color: "grey" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.sortedDatasetSpaces, function(
                                    item
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: item.sid,
                                        on: {
                                          click: function($event) {
                                            return _vm.openSpace(
                                              item.space_long_id,
                                              item.sid,
                                              item.iid,
                                              item.snid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between align-center"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.space_long_id) +
                                                  " "
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    outlined: "",
                                                    color: "grey lighten-1",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.spaceRole(
                                                        item.space_role
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.fetchingSpaces === true
                ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _vm.fetchingInstances === true || _vm.showInstanceMenu === true
                ? _c("v-icon", [_vm._v("keyboard_arrow_right")])
                : _vm._e(),
              _vm.showInstanceMenu === true
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "close-on-content-click": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-shrink-1 full-width-button",
                                      attrs: { text: "", color: "primary" }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                          },
                                          [_vm._v(_vm._s(_vm.instanceName))]
                                        ),
                                        _c("v-icon", [
                                          _vm._v("arrow_drop_down")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3626184703
                      ),
                      model: {
                        value: _vm.instanceMenu,
                        callback: function($$v) {
                          _vm.instanceMenu = $$v
                        },
                        expression: "instanceMenu"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "overflow-y-auto",
                          staticStyle: { "max-height": "500px" },
                          attrs: { nav: "", dense: "" }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-text-field", {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  autofocus: "",
                                  "append-icon": "search",
                                  label: "Filter",
                                  dense: "",
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.instanceSearch,
                                  callback: function($$v) {
                                    _vm.instanceSearch = $$v
                                  },
                                  expression: "instanceSearch"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { attrs: { disabled: "" } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "primary--text caption text-uppercase d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flex-column",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("group")]
                                              ),
                                              _vm._v("instances")
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mt-1",
                                            attrs: { color: "grey" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.sortedInstances, function(item) {
                            return _c(
                              "v-list-item",
                              {
                                key: item.iid,
                                attrs: {
                                  disabled: _vm.disabledInstance(
                                    item.instance_name,
                                    item.data
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openInstance(
                                      item.instance_name,
                                      item.iid,
                                      item.role
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between align-center"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.instance_name) + " "
                                      ),
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "ml-3",
                                          attrs: {
                                            outlined: "",
                                            color: "grey lighten-1",
                                            "x-small": ""
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.role))]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm.fetchingInstances === true
                ? _c("v-progress-circular", {
                    attrs: { size: 30, color: "primary", indeterminate: "" }
                  })
                : _vm._e(),
              _vm.fetchingSnapshots === true || _vm.showSnapshotMenu === true
                ? _c("v-icon", [_vm._v("keyboard_arrow_right")])
                : _vm._e(),
              _vm.showSnapshotMenu === true
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "close-on-content-click": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-shrink-1 full-width-button",
                                      attrs: { text: "", color: "primary" }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                          },
                                          [_vm._v(_vm._s(_vm.snapshotName))]
                                        ),
                                        _c("v-icon", [
                                          _vm._v("arrow_drop_down")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1808011386
                      ),
                      model: {
                        value: _vm.snapshotMenu,
                        callback: function($$v) {
                          _vm.snapshotMenu = $$v
                        },
                        expression: "snapshotMenu"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "overflow-y-auto",
                          staticStyle: { "max-height": "500px" },
                          attrs: { nav: "", dense: "" },
                          model: {
                            value: _vm.snapshot,
                            callback: function($$v) {
                              _vm.snapshot = $$v
                            },
                            expression: "snapshot"
                          }
                        },
                        [
                          _c("v-list-item", [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex justify-space-between",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "ma-0 pa-0",
                                  attrs: {
                                    autofocus: "",
                                    "append-icon": "search",
                                    label: "Filter",
                                    dense: "",
                                    "single-line": ""
                                  },
                                  model: {
                                    value: _vm.snapshotSearch,
                                    callback: function($$v) {
                                      _vm.snapshotSearch = $$v
                                    },
                                    expression: "snapshotSearch"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      loading:
                                                        _vm.fetchingSnapshots
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.$store.dispatch(
                                                          "instanceStore/fetchInstanceSnapshots",
                                                          _vm.$route.params.iid
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("refresh")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1705806840
                                    )
                                  },
                                  [_c("span", [_vm._v("Refresh snapshots")])]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm.currentState.length &&
                          _vm.isInstanceEditor === true &&
                          _vm.isDistributedInstance === false
                            ? _c(
                                "v-list-item",
                                { attrs: { disabled: "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "primary--text caption text-uppercase d-flex align-center flex-wrap"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column",
                                              staticStyle: { width: "100%" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" }
                                                    },
                                                    [_vm._v("build")]
                                                  ),
                                                  _vm._v("mutable state")
                                                ],
                                                1
                                              ),
                                              _c("v-divider", {
                                                staticClass: "mt-1",
                                                attrs: { color: "grey" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentState.length &&
                          _vm.isInstanceEditor === true &&
                          _vm.isDistributedInstance === false
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.openSnapshot(
                                        _vm.currentState[0].long_id,
                                        _vm.currentState[0].snid
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.currentState[0].long_id))
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "primary--text caption text-uppercase d-flex align-center flex-wrap"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flex-column",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between align-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" }
                                                    },
                                                    [_vm._v("camera_alt")]
                                                  ),
                                                  _vm._v("immutable states")
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "info",
                                                                    icon: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.goToSnapshotTimelineView()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "timeline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2773548375
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Timeline view of snapshots"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            attrs: { color: "grey" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.sortedSnapshots.length
                            ? _c(
                                "div",
                                _vm._l(_vm.sortedSnapshots, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.snid,
                                      on: {
                                        click: function($event) {
                                          return _vm.openSnapshot(
                                            item.long_id,
                                            item.snid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.long_id))
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm.currentSpaceType !==
                                _vm.spaceTypes.VENDOR_SPACE &&
                              _vm.isDevelopment === true &&
                              _vm.isInstanceEditor === true
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.addSnapshot(
                                        _vm.snapshotTypes.QUICK_SNAPSHOT
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "subtitle-2 d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v("mdi-camera-plus-outline")]
                                      ),
                                      _vm._v("Quick snapshot")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color:
                                                              "grey lighten-1"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-information"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2792354461
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "This will create and save a snapshot of your current state, including all your files, tables, and applications."
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.fetchingSnapshots === true
                ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.goToDashboard } },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("home")
                  ])
                ],
                1
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="flex-grow-1 flex-shrink-1" style="min-width:0">
        <div v-if="$route.name !== 'app-open' && $route.params.oid" class="d-flex justify-start align-center">
            <v-btn :class="$route.params.sid ? 'ml-3' : ''" @click="goToDashboard" text>
                <v-icon small color="primary">home</v-icon>
            </v-btn>
            <v-icon>keyboard_arrow_right</v-icon>
            <v-menu v-model="organizationMenu" offset-y v-if="showOrgMenu === true" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="fetchingSpaces" v-on="on" text color="primary" class="flex-shrink-1 full-width-button">
                        <div class="d-flex align-center">
                            <div class="primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ organizationName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                    <v-list-item>
                        <v-text-field
                            autofocus
                            v-model="organizationSearch"
                            class="ma-0 pa-0"
                            append-icon="search"
                            label="Filter"
                            dense
                            single-line
                        ></v-text-field>
                    </v-list-item>
                    <v-list-item disabled>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text caption text-uppercase d-flex align-center">
                                <div class="d-flex flex-column" style="width:100%">
                                    <div class="d-flex align-center"><v-icon small class="mr-1">apartment</v-icon>organizations</div>
                                    <v-divider class="mt-1" color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="item in sortedOrgNames" :key="item.long_id" @click="fetchOrgSpaces(item.long_id)">
                        <v-list-item-title>
                            <div class="d-flex justify-space-between align-center">
                                {{ item.long_id }}
                                <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ item.role }}</v-chip>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-icon>keyboard_arrow_right</v-icon>
            <v-menu v-model="spaceMenu" offset-y v-if="showSpaceMenu === true" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="primary" class="flex-shrink-1 full-width-button">
                        <div class="d-flex align-center">
                            <div class="primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ spaceName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                    <v-list-item>
                        <v-text-field v-model="spaceSearch" autofocus class="ma-0 pa-0" append-icon="search" label="Filter" dense single-line></v-text-field>
                    </v-list-item>
                    <div v-if="sortedEducationSpaces.length > 0">
                        <v-list-item disabled>
                            <v-list-item-content>
                                <v-list-item-title class="primary--text caption text-uppercase d-flex align-center">
                                    <div class="d-flex flex-column" style="width:100%">
                                        <div class="d-flex align-center"><v-icon small class="mr-1">school</v-icon>courses</div>
                                        <v-divider class="mt-1" color="grey"></v-divider>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="item in sortedEducationSpaces"
                            :key="item.sid"
                            @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)"
                        >
                            <v-list-item-title>
                                <div class="d-flex justify-space-between align-center">
                                    {{ item.space_long_id }}
                                    <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-if="sortedResearchSpaces.length > 0">
                        <v-list-item disabled :class="sortedEducationSpaces.length ? 'mt-5' : ''">
                            <v-list-item-content>
                                <v-list-item-title class="primary--text caption text-uppercase d-flex align-center">
                                    <div class="d-flex flex-column" style="width:100%">
                                        <div class="d-flex align-center"><v-icon small class="mr-1">mdi-beaker</v-icon>research projects</div>
                                        <v-divider class="mt-1" color="grey"></v-divider>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="item in sortedResearchSpaces" :key="item.sid" @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)">
                            <v-list-item-title>
                                <div class="d-flex justify-space-between align-center">
                                    {{ item.space_long_id }}
                                    <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-if="sortedDatasetSpaces.length > 0">
                        <v-list-item disabled :class="sortedResearchSpaces.length || sortedEducationSpaces.length ? 'mt-5' : ''">
                            <v-list-item-content>
                                <v-list-item-title class="primary--text caption text-uppercase d-flex align-center">
                                    <div class="d-flex flex-column" style="width:100%">
                                        <div class="d-flex align-center"><v-icon small class="mr-1">mdi-database</v-icon>datasets</div>
                                        <v-divider class="mt-1" color="grey"></v-divider>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="item in sortedDatasetSpaces" :key="item.sid" @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)">
                            <v-list-item-title>
                                <div class="d-flex justify-space-between align-center">
                                    {{ item.space_long_id }}
                                    <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-menu>
            <v-progress-circular v-else-if="fetchingSpaces === true" indeterminate></v-progress-circular>
            <v-icon v-if="fetchingInstances === true || showInstanceMenu === true">keyboard_arrow_right</v-icon>
            <v-menu v-model="instanceMenu" offset-y v-if="showInstanceMenu === true" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="primary" class="flex-shrink-1 full-width-button">
                        <div class="d-flex align-center">
                            <div class="primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ instanceName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                    <v-list-item>
                        <v-text-field v-model="instanceSearch" autofocus class="ma-0 pa-0" append-icon="search" label="Filter" dense single-line></v-text-field>
                    </v-list-item>
                    <v-list-item disabled>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text caption text-uppercase d-flex align-center">
                                <div class="d-flex flex-column" style="width:100%">
                                    <div class="d-flex align-center"><v-icon small class="mr-1">group</v-icon>instances</div>
                                    <v-divider class="mt-1" color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-for="item in sortedInstances"
                        :key="item.iid"
                        @click="openInstance(item.instance_name, item.iid, item.role)"
                        :disabled="disabledInstance(item.instance_name, item.data)"
                    >
                        <v-list-item-title>
                            <div class="d-flex justify-space-between align-center">
                                {{ item.instance_name }}
                                <v-chip outlined color="grey lighten-1" class="ml-3" x-small> {{ item.role }}</v-chip>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-progress-circular :size="30" color="primary" indeterminate v-else-if="fetchingInstances === true"></v-progress-circular>
            <v-icon v-if="fetchingSnapshots === true || showSnapshotMenu === true">keyboard_arrow_right</v-icon>
            <v-menu v-model="snapshotMenu" offset-y v-if="showSnapshotMenu === true" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="primary" class="flex-shrink-1 full-width-button">
                        <div class="d-flex align-center">
                            <div class="primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ snapshotName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height:500px" class="overflow-y-auto" nav dense v-model="snapshot">
                    <v-list-item>
                        <div style="width:100%" class="d-flex justify-space-between">
                            <v-text-field
                                v-model="snapshotSearch"
                                autofocus
                                class="ma-0 pa-0"
                                append-icon="search"
                                label="Filter"
                                dense
                                single-line
                            ></v-text-field>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon
                                        :loading="fetchingSnapshots"
                                        v-on="on"
                                        @click="$store.dispatch('instanceStore/fetchInstanceSnapshots', $route.params.iid)"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh snapshots</span>
                            </v-tooltip>
                        </div>
                    </v-list-item>
                    <v-list-item disabled v-if="currentState.length && isInstanceEditor === true && isDistributedInstance === false">
                        <v-list-item-content>
                            <v-list-item-title class="primary--text caption text-uppercase d-flex align-center flex-wrap">
                                <div class="d-flex flex-column" style="width:100%">
                                    <div class="d-flex align-center"><v-icon small class="mr-1">build</v-icon>mutable state</div>
                                    <v-divider class="mt-1" color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="currentState.length && isInstanceEditor === true && isDistributedInstance === false"
                        @click="openSnapshot(currentState[0].long_id, currentState[0].snid)"
                    >
                        <v-list-item-title>{{ currentState[0].long_id }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text caption text-uppercase d-flex align-center flex-wrap">
                                <div class="d-flex flex-column" style="width:100%">
                                    <div class="d-flex justify-space-between align-center">
                                        <div class="d-flex align-center"><v-icon small class="mr-1">camera_alt</v-icon>immutable states</div>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" small color="info" icon @click="goToSnapshotTimelineView()">
                                                    <v-icon>timeline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Timeline view of snapshots</span>
                                        </v-tooltip>
                                    </div>
                                    <v-divider color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <div v-if="sortedSnapshots.length">
                        <v-list-item v-for="item in sortedSnapshots" :key="item.snid" @click="openSnapshot(item.long_id, item.snid)">
                            <v-list-item-title>{{ item.long_id }}</v-list-item-title>
                        </v-list-item>
                    </div>
                    <v-list-item
                        v-else-if="currentSpaceType !== spaceTypes.VENDOR_SPACE && isDevelopment === true && isInstanceEditor === true"
                        @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)"
                    >
                        <v-list-item-title class="subtitle-2 d-flex align-center"
                            ><v-icon small class="mr-1">mdi-camera-plus-outline</v-icon>Quick snapshot</v-list-item-title
                        >
                        <v-list-item-action>
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                </template>
                                <span>This will create and save a snapshot of your current state, including all your files, tables, and applications.</span>
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-progress-circular v-else-if="fetchingSnapshots === true" indeterminate></v-progress-circular>
        </div>
        <div v-else>
            <v-btn @click="goToDashboard" text>
                <v-icon color="primary">home</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
import { breadcrumbs } from '@/mixins/breadcrumbs'
import { enumsData } from '@/mixins/enums'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import { mapGetters } from 'vuex'
import { snapshotEnums } from '@/mixins/snapshot'

export default {
    name: 'BreadcrumbsLargeScreen',
    mixins: [breadcrumbs, enumsData, createQuickAndDetailedSnapshots, snapshotEnums],
    computed: {
        ...mapGetters('spaceStore', ['currentSpaceType']),
        ...mapGetters('instanceStore', ['isInstanceEditor'])
    }
}
</script>
